
import 'core-js/stable'; import 'regenerator-runtime/runtime';
import ComponentLoader from '/root/project/node_modules/@uplight/marketplace-components/lib/ComponentLoader';
import CartRecommendedProducts from '/root/project/node_modules/@uplight/marketplace-components/src/slots/CartRecommendedProducts/containers/CartRecommendedProducts';
import ErrorUnsupportedBrowser from '/root/project/node_modules/@uplight/marketplace-components/src/errorpage/PageUnsupportedBrowser/PageUnsupportedBrowser';
import HeaderBanner from '/root/project/node_modules/@uplight/marketplace-components/src/slots/HeaderBanner/HeaderBanner';
import Page from '/root/project/node_modules/@uplight/marketplace-components/src/managedContent/Page';
import ProductRecommendedProducts from '/root/project/node_modules/@uplight/marketplace-components/src/slots/ProductRecommendedProducts/ProductRecommendedProducts';
import ProgramPage from '/root/project/node_modules/@uplight/marketplace-components/src/managedContent/ProgramPage';
import SiteFooter from '/root/project/node_modules/@uplight/marketplace-components/src/layout/SiteFooter/containers/SiteFooter';
import SiteHeader from '/root/project/node_modules/@uplight/marketplace-components/src/layout/SiteHeader/containers/SiteHeader';
import SiteHeaderFocus from '/root/project/node_modules/@uplight/marketplace-components/src/layout/SiteHeaderFocus/SiteHeaderFocus';
import SkipToContent from '/root/project/node_modules/@uplight/marketplace-components/src/slots/SkipToContent/SkipToContent';
import Trigger from '/root/project/node_modules/@uplight/marketplace-components/src/error/Trigger/Trigger';
import UserMessages from '/root/project/node_modules/@uplight/marketplace-components/src/error/UserMessages/UserMessages';


const componentList = [
  CartRecommendedProducts,
  ErrorUnsupportedBrowser,
  HeaderBanner,
  Page,
  ProductRecommendedProducts,
  ProgramPage,
  SiteFooter,
  SiteHeader,
  SiteHeaderFocus,
  SkipToContent,
  Trigger,
  UserMessages,
];

new ComponentLoader(componentList).load();
